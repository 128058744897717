import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormListFieldData } from 'antd/lib/form/FormList';
import {
  Row,
  Form,
  Input,
  Popconfirm,
  Button,
  Select,
  Typography,
  FormInstance
} from 'antd';
import { stringifyNumber } from '../../common/utils';
import { months } from '../../common/constants';
import moment from 'moment';
import { useViewport } from '../../common/hooks';

const { Title, Text } = Typography;
const { Option } = Select;

interface IProps {
  form?: FormInstance;
  field: FormListFieldData;
  remove: () => void;
  amountOfFields: number;
  student?: boolean;
}

interface ICompareStartEnd {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
}

const WorkExperienceTemplate: React.FC<IProps> = ({
  form,
  field,
  remove,
  amountOfFields,
  student
}) => {
  if (student) {
    return <></>;
  }

  const { isMobile } = useViewport();

  const defaultDateComparison: ICompareStartEnd = {
    startMonth: 0,
    startYear: 0,
    endMonth: 0,
    // we set endYear to a large number so it will not show error
    endYear: 10000
  };
  const [showReason, setShowReason] = useState<string>();
  const [dateComparison, setDateComparison] = useState<ICompareStartEnd>(
    defaultDateComparison
  );

  useEffect(() => {
    const formData = form.getFieldsValue();

    if (formData?.workExperience) {
      setShowReason(formData.workExperience[field.key]?.leavingReason);
    }
  }, []);

  const requiredRule = (validationMessage: string) => [
    { required: true, message: validationMessage, whitespace: true }
  ];

  const isNotLastEntry = () => field.fieldKey + 1 < amountOfFields;

  const dateDependencies = (fieldKey: number) => {
    const mappedArr = [];
    ['fromMonth', 'fromYear', 'toMonth', 'toYear'].map(dependency => {
      mappedArr.push(['workExperience', fieldKey, dependency]);
    });
    return mappedArr;
  };

  const checkDates = (dateComparison: ICompareStartEnd) => {
    const { startMonth, startYear, endMonth, endYear } = dateComparison;

    const checkInvalidDates =
      startYear > endYear || (startYear == endYear && startMonth > endMonth);

    if (checkInvalidDates)
      return Promise.reject(
        new Error('The start date must be earlier than the end date!')
      );
    else {
      return Promise.resolve();
    }
  };

  const currentYear = parseInt(moment().format('YYYY'));

  return (
    <>
      <Form.Item label={<></>} className="remove-prefix">
        <Title
          level={2}
          style={{
            textTransform: 'capitalize',
            marginBottom: 0,
            marginTop: 10
          }}>
          {stringifyNumber(field.fieldKey + 1)} work experience
        </Title>
      </Form.Item>

      <Form.Item
        name={[field.name, 'title']}
        label="Job Title or Role"
        rules={requiredRule('Please enter a job title')}>
        <Input placeholder="Example: Warehouse Packer" />
      </Form.Item>

      <Form.Item
        name={[field.name, 'company']}
        label="Company Name"
        rules={requiredRule('Please enter a company name')}>
        <Input placeholder="Example: FairPrice" />
      </Form.Item>

      <Form.Item
        label={<Text> <Text type="danger">*</Text> Start Date</Text>}
        rules={[
          {
            required: true,
            message: 'Please select start month and year'
          }
        ]}>
        <Input.Group compact={isMobile ? false : true}>
          <Form.Item
            name={[field.name, 'fromMonth']}
            noStyle
            dependencies={dateDependencies(field.fieldKey)}
            rules={[
              {
                required: true,
                message: 'Please enter a start month'
              }
            ]}>
            <Select
              onChange={value =>
                setDateComparison({
                  ...dateComparison,
                  startMonth: parseInt(value.toString())
                })
              }
              style={{ width: isMobile ? '100%' : '50%', marginBottom: 5 }}
              placeholder="Start month">
              {months.map(month => {
                return (
                  <Option
                    id={`data-test-${field.key}-from-month-${month.label}`}
                    value={month.value}>
                    {month.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name={[field.name, 'fromYear']}
            noStyle
            dependencies={dateDependencies(field.fieldKey)}
            rules={[
              {
                required: true,
                message: 'Please enter a start year'
              },
              {
                validator: (_, value) =>
                  checkDates({ ...dateComparison, startYear: value })
              }
            ]}>
            <Select
              onChange={value =>
                setDateComparison({
                  ...dateComparison,
                  startYear: parseInt(value.toString())
                })
              }
              style={{ width: isMobile ? '100%' : '50%', marginBottom: 5 }}
              placeholder="Start year">
              {Array.from(Array(100).keys()).map((i: number) => {
                return (
                  <Option
                    id={`data-test-${field.key}-from-year-${currentYear - i}`}
                    value={(currentYear - i).toString()}>
                    {currentYear - i}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item
        label={<Text> <Text type="danger">*</Text> End Date</Text>}
        rules={[
          {
            required: true,
            message: 'Please select end month and year'
          }
        ]}>
        <Input.Group compact={isMobile ? false : true}>
          <Form.Item
            name={[field.name, 'toMonth']}
            noStyle
            dependencies={dateDependencies(field.fieldKey)}
            rules={[
              {
                required: isNotLastEntry() ? true : false,
                message: 'Please enter an end month'
              }
            ]}>
            <Select
              onChange={value =>
                setDateComparison({
                  ...dateComparison,
                  endMonth: parseInt(value.toString())
                })
              }
              style={{ width: isMobile ? '100%' : '50%', marginBottom: 5 }}
              placeholder="End month">
              {months.map(month => {
                return (
                  <Option
                    id={`data-test-${field.key}-to-month-${month.label}`}
                    value={month.value}>
                    {month.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name={[field.name, 'toYear']}
            dependencies={dateDependencies(field.fieldKey)}
            noStyle
            rules={[
              {
                required: isNotLastEntry() ? true : false,
                message: 'Please enter an end year'
              },
              {
                validator: (_, value) =>
                  value
                    ? checkDates({ ...dateComparison, endYear: value })
                    : Promise.resolve()
              }
            ]}>
            <Select
              onChange={value =>
                setDateComparison({
                  ...dateComparison,
                  endYear: parseInt(value.toString())
                })
              }
              style={{ width: isMobile ? '100%' : '50%', marginBottom: 5 }}
              placeholder="End year">
              {Array.from(Array(100).keys()).map((i: number) => {
                if (currentYear - i < dateComparison.startYear) return;
                return (
                  <Option
                    id={`data-test-${field.key}-to-year-${currentYear - i}`}
                    value={(currentYear - i).toString()}>
                    {currentYear - i}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Job Scope" required>
        <Form.List
          name={[field.name, 'description_list']}
          initialValue={[{ item: '' }, { item: '' }, { item: '' }]}>
          {(descFields, { add, remove }) => (
            <>
              {descFields.map((descField, j) => {
                return (
                  <Row wrap={false} align="middle" style={{ marginBottom: 5 }}>
                    <Form.Item
                      {...descField}
                      name={[descField.name, 'item']}
                      fieldKey={[descField.key, 'item']}
                      style={{ ...s.w100, marginBottom: 0 }}
                      rules={
                        j <= 1 && [
                          {
                            required: true,
                            message: 'Please fill in a job scope',
                            whitespace: true
                          }
                        ]
                      }>
                      <Input
                        placeholder={
                          j == 0
                            ? 'Example: Managed over 10 customer accounts'
                            : j === 1
                              ? 'Example: Supervised 5 people in my department'
                              : j === 2
                                ? 'Example: Set up inventory system'
                                : null
                        }
                      />
                    </Form.Item>

                    {j > 2 && (
                      <MinusCircleOutlined
                        onClick={() => remove(descField.name)}
                        style={s.removeBtn}
                      />
                    )}
                  </Row>
                );
              })}

              <Form.Item className="remove-prefix">
                <Button type="dashed" block onClick={add}>
                  <PlusOutlined /> Add Responsibility
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      {/* {isNotLastEntry() && (
        <>
          <Form.Item
            name={[field.name, 'leavingReason']}
            label="Reason for leaving">
            <Select
              onChange={val => setShowReason(val as string)}
              placeholder="Reason for leaving"
              options={[
                {
                  label: 'New Opportunities',
                  value: 'New Opportunities'
                },
                {
                  label: 'Different Work Culture',
                  value: 'Different Work Culture'
                },
                { label: 'Others', value: 'Others' }
              ]}
            />
          </Form.Item>

          {showReason === 'Others' && (
            <Form.Item
              name={[field.name, 'leavingReasonText']}
              className="remove-prefix"
              label={<></>}
              rules={[{ required: true, message: 'Please provide a reason.' }]}>
              <Input placeholder="Please provide a reason for leaving" />
            </Form.Item>
          )}
        </>
      )} */}

      {field.key >= 2 && (
        <Popconfirm
          title="Are you sure you want to remove this experience?"
          onConfirm={remove}>
          <Button type="dashed" danger block>
            Remove
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default WorkExperienceTemplate;

const s: Stylesheet = {
  inline: {
    display: 'inline-block',
    width: '100%'
  },
  w100: {
    width: '100%'
  },
  removeBtn: {
    fontSize: 20,
    marginLeft: 5,
    color: '#f81d22',
    display: 'flex',
    alignItems: 'center'
  }
};
