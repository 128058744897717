import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { Button, Divider, Form, Row, Space, Switch, Typography } from 'antd';
import { navigate } from 'gatsby';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WorkExperienceTemplate from '../components/form/work-experience';
import DefaultLayout from '../components/layouts/default.layout';
import { Navigation } from '../components/navigation';
import { updatePersistentData, updateWorkExperience } from '../state/actions';
import { IState } from '../state/types';

const { useState } = React;
const { Title, Paragraph, Text } = Typography;

const WorkExperiencePage: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const workExperience = useSelector(
    (state: IState) => state.workExperience || []
  );

  const isStudent = useSelector(
    (state: IState) => (state.persistence.student as boolean) || false
  );

  const [student, setStudent] = useState<boolean>(isStudent);
  const [showFillInAllFields, setshowFillInAllFields] = useState<boolean>(
    false
  );

  const handleNext = async () => {
    form.submit();

    form
      .validateFields()
      .then(values => {
        setshowFillInAllFields(false);
        const formattedData = valuesToData(values.workExperience);
        dispatch(updateWorkExperience(formattedData));
        navigate(`/education${window.location.search}`);
      })
      .catch(err => {
        setshowFillInAllFields(true);
      });
  };

  const valuesToData = (values: any): IWorkExperience[] =>
    values.map((exp: Record<string, any>) => ({
      title: exp.title,
      company: exp.company,
      fromMonth: exp.fromMonth,
      fromYear: exp.fromYear,
      toMonth: exp.toMonth,
      toYear: exp.toYear,
      description: exp.description_list
        .filter(description => {
          if (description.item) return description.item;
        })
        .map(description => description.item),
      leavingReason: exp.leavingReason,
      leavingReasonText: exp.leavingReasonText
    }));

  const initialValues = () => {
    if (workExperience.length === 0)
      return [
        {
          title: null,
          company: null,
          fromMonth: null,
          fromYear: null,
          toMonth: null,
          toYear: null,
          description: [],
          leavingReason: null
        },
        {
          title: null,
          company: null,
          fromMonth: null,
          fromYear: null,
          toMonth: null,
          toYear: null,
          description: [],
          leavingReason: null
        }
      ];

    return workExperience.map((data: IWorkExperience) => ({
      title: data.title,
      company: data.company,
      fromMonth: data.fromMonth,
      fromYear: data.fromYear,
      toMonth: data.toMonth ? data.toMonth : null,
      toYear: data.toYear ? data.toYear : null,
      description_list: data?.description?.map(item => ({ item })),
      leavingReason: data.leavingReason,
      leavingReasonText: data?.leavingReasonText
    }));
  };

  return (
    <DefaultLayout>
      <div style={s.header}>
        <Title level={2}>Work Experience</Title>
        <Paragraph>Secondly, tell me about your work experience.</Paragraph>
      </div>
      <Divider />

      <Row style={{ marginBottom: 30 }} justify="end">
        <p style={{ fontSize: 18, marginRight: 10, marginBottom: 0 }}>
          I am a student and have <b>no work experience</b>
        </p>

        <Switch
          defaultChecked={isStudent}
          onChange={bool => {
            setStudent(bool);
            dispatch(updatePersistentData('student', bool));
          }}
        />
      </Row>

      <Form
        labelCol={{ span: 6, lg: 8 }}
        form={form}
        initialValues={{
          workExperience: initialValues()
        }}>
        <Form.List name="workExperience">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => (
                <Fragment key={i}>
                  <WorkExperienceTemplate
                    student={student}
                    form={form}
                    field={field}
                    amountOfFields={fields.length}
                    remove={() => remove(field.name)}
                  />

                  {!student && <Divider />}
                </Fragment>
              ))}

              {!student && (
                <Button type="dashed" block onClick={add}>
                  <PlusOutlined /> Add Work Experience
                </Button>
              )}
            </>
          )}
        </Form.List>
      </Form>

      <div style={s.header}>
        <Space direction="vertical">
          {!student && <p>Please provide at least 2 working experience</p>}
          {showFillInAllFields && (
            <Text type="danger">
              {' '}
              <ExclamationCircleOutlined /> Fill in All The Fields! Please
              scroll up!
            </Text>
          )}

          <Navigation
            step={3}
            goBackHandler={() => navigate(`/${window.location.search}`)}
            goNextHandler={handleNext}
          />
        </Space>
      </div>
    </DefaultLayout>
  );
};

export default WorkExperiencePage;

const s: Stylesheet = {
  header: {
    textAlign: 'center',
    marginTop: 50
  }
};
