/**
 * Reasons for leaving
 */
export const REASONS_FOR_LEAVING: AntdSelectOption[] = [
  { label: 'Management', value: 'management' },
  {
    label: 'Better career opportunities',
    value: 'Better career opportunities'
  },
  { label: 'Relocation', value: 'Relocation' },
  { label: 'Looking for more challenge', value: 'Looking for more challenge' },
  { label: 'Change of environment', value: 'Change of environment' },
  { label: 'Prospecting career growth', value: 'Prospecting career growth' },
  { label: 'Health Issues', value: 'Health Issues' },
  { label: 'Personal Reasons', value: 'Personal Reasons' }
];

/**
 * Education levels
 */
export const EDUCATION_LEVELS: AntdSelectOption[] = [
  { label: 'PSLE', value: 'PSLE' },
  { label: "N' Levels", value: "N' Levels" },
  { label: "'O' Levels", value: "'O' Levels" },
  { label: 'SPM', value: 'SPM' },
  { label: "'A' Levels", value: "'A' Levels" },
  { label: 'Nitec', value: 'Nitec' },
  { label: 'Higher Nitec', value: 'Higher Nitec' },
  { label: 'Diploma', value: 'Diploma' },
  { label: "Bachelor's Degree", value: "Bachelor's Degree" },
  { label: "Master's Degree", value: "Master's Degree" },
  { label: 'Doctorate', value: 'Doctorate' },
  { label: 'Other', value: 'Other' }
];

/**
 * Months mapping
 */
export const months: { value: string; label: string }[] = [
  { value: '01', label: 'Jan' },
  { value: '02', label: 'Feb' },
  { value: '03', label: 'Mar' },
  { value: '04', label: 'Apr' },
  { value: '05', label: 'May' },
  { value: '06', label: 'Jun' },
  { value: '07', label: 'Jul' },
  { value: '08', label: 'Aug' },
  { value: '09', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' }
];
